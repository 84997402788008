import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import AppLayout from "./layouts/AppLayout";
import Login from "./layouts/LoginLayout";

import VueCarousel from '@chenfengyuan/vue-carousel';
let app = createApp(App)
app.component('app-layout', AppLayout)
app.component('login-layout', Login)
app.component(VueCarousel.name, VueCarousel);
app.config.globalProperties.globalVar = 'globalVar'
app.config.globalProperties.$filters = {
    formatNumber(number) {
        return Intl.NumberFormat().format(number);
    },
    pretty: function(value) {
        return JSON.stringify(JSON.parse(value), null, 2);
    }
}

app.use(router).use(store).mount('#app')