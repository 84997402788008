import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'




const routes = [{
        path: "/",
        name: "login",
        meta: { layout: 'login-layout' },
        component: Login,
    },
    {
        path: '/main',
        name: 'main',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Main.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
        path: '/reception',
        name: 'reception',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Reception.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Dashboard.vue')
    },
    {
        path: '/hts',
        name: 'Hts',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Hts.vue')
    },
    {
        path: '/map',
        name: 'Map',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Map.vue')
    },
    {
        path: '/tx_new',
        name: 'TXNEW',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Tx_New.vue')
    },
    {
        path: '/tx_curr',
        name: 'TXCURR',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Tx_Curr.vue')
    },
    {
        path: '/viralload',
        name: 'Viralload',
        meta: { layout: 'app-layout' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Tx_Viralload.vue')
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router