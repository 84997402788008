<template>
  <!-- Loader ends-->
  <!-- page-wrapper Start       -->
  <div class="page-wrapper compact-wrapper" id="pageWrapper">
    <!-- Page Header Start-->
    <div class="page-main-header">
      <div class="main-header-right row m-0">
        <div class="main-header-left">
          <div class="logo-wrapper">
            <a href="/"
              ><img
                class="img-fluid"
                src="assets/images/logo/ihvn-logo.jpg"
                alt=""
            /></a>
          </div>
          <div class="dark-logo-wrapper">
            <a href="/"
              ><img
                class="img-fluid"
                src="assets/images/logo/ihvn-logo.jpg"
                alt=""
            /></a>
          </div>
          <div class="toggle-sidebar">
            <i
              class="status_toggle middle"
              data-feather="align-center"
              id="sidebar-toggle"
            ></i>
          </div>
        </div>

        <div class="nav-right col pull-right right-menu p-0">
          <ul class="nav-menus">
            <li>
              <a
                class="text-dark"
                href="#!"
                onclick="javascript:toggleFullScreen()"
                ><i data-feather="maximize"></i
              ></a>
            </li>

            <li class="onhover-dropdown p-0">
              <button class="btn btn-primary-light" type="button">
                <a href="javascript:void(0)" @click="logout"
                  ><i data-feather="log-out"></i>Log out</a
                >
              </button>
            </li>
          </ul>
        </div>
        <div class="d-lg-none mobile-toggle pull-right w-auto">
          <i data-feather="more-horizontal"></i>
        </div>
      </div>
    </div>
    <!-- Page Header Ends                              -->
    <!-- Page Body Start-->
    <div class="page-body-wrapper sidebar-icon">
      <!-- Page Sidebar Start-->
      <header class="main-nav">
        <div class="sidebar-user text-center">
          <a class="setting-primary" href="javascript:void(0)"
            ><i data-feather="settings"></i></a
          ><img
            class="img-90 rounded-circle"
            src="assets/images/dashboard/1.png"
            alt=""
          />
          <a href="#">
            <h6 class="mt-3 f-14 f-w-600">
              IHVN {{ this.$store.state.auth.user.data.user.name }}
            </h6></a
          >

          <p class="mb-0 font-roboto">
            IHVN {{ this.$store.state.auth.user.data.user.state }} State
          </p>
        </div>
        <nav>
          <div class="main-navbar">
            <div class="left-arrow" id="left-arrow">
              <i data-feather="arrow-left"></i>
            </div>
            <div id="mainnav">
              <ul class="nav-menu custom-scrollbar">
                <li class="back-btn">
                  <div class="mobile-back text-end">
                    <span>Back</span
                    ><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                  </div>
                </li>
                <li class="sidebar-main-title">
                  <div>
                    <h6>Treament Performance</h6>
                  </div>
                </li>
                <li class="dropdown">
                  <router-link :to="'/tx_curr'" class="nav-link menu-title">
                    <i data-feather="home"></i> Treament Current
                  </router-link>
                </li>
                <li class="dropdown">
                  <router-link :to="'/tx_new'" class="nav-link menu-title">
                    <i data-feather="home"></i> Treament new
                  </router-link>
                </li>
                <li class="dropdown">
                  <router-link :to="'/viralload'" class="nav-link menu-title">
                    <i data-feather="home"></i> Viralload 
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="right-arrow" id="right-arrow">
              <i data-feather="arrow-right"></i>
            </div>
          </div>
        </nav>
      </header>
      <!-- Page Sidebar Ends-->
      <div class="page-body">
        <!-- Container-fluid starts-->
        <div class="container-fluid dashboard-default-sec">
          <router-view />
        </div>
        <!-- Container-fluid Ends-->
      </div>
      <!-- footer start-->
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 footer-copyright">
              <p class="mb-0">Copyright 2021-22 © viho All rights reserved.</p>
            </div>
            <div class="col-md-6">
              <p class="pull-right mb-0">
                Hand crafted & made with
                <i class="fa fa-heart font-secondary"></i>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>



<script>
export default {
  name: "Login",

  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>